import { Link } from "gatsby";
import React from "react";
import { useLanguage } from "../../languageContext";
import RMArrowBlack from "../../images/arrowBlack.png";
import RMArrowBlackEn from "../../images/arrowBlackEn.png";

// import RMArrowIn from "../../images/arrowIn.png";
import RMArrowBlackJa from "../../images/arrowBlackJa.png";
import RMArrowBlackIn from "../../images/arrowBlackIn.png";

const InsightsPageItem = ({ heading, date, para, url, image, thumbnail }) => {
  const currentLanguage = useLanguage();
  return (
    <div className="insights-item">
      <Link to={`/insight/${url ? url : "404"}`}>
        <div className="image-wrapper">
          <img src={thumbnail ? thumbnail.url : "404"} alt={heading}></img>
        </div>
        <div className="slider-item-info">
          <h2 className="insight-block-heading para titlecase-text font-semi-bold">
            {heading}
          </h2>
          {/* {date && <div className="date">{date}</div>} */}
          <p className="insights-para semi-para ">{para}</p>
          <label
            className={`read-more semi-para uppercase-text read-${
              currentLanguage ? currentLanguage : "en"
            }`}
          >
            Read More
            <div className="image-container">
              <img
                src={
                  currentLanguage === "en"
                    ? RMArrowBlackEn
                    : currentLanguage === "in"
                    ? RMArrowBlackIn
                    : currentLanguage === "ja"
                    ? RMArrowBlackJa
                    : RMArrowBlack
                }
                alt="Read More Image. This will forward to insights page."
              />
            </div>
          </label>
        </div>
      </Link>
    </div>
  );
};

export default InsightsPageItem;
